import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';

const Project = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout backgroundColor={post.frontmatter.backgroundColor} pageTitle={post.frontmatter.title}>
      <section className='full-width'>
        <h1>{post.frontmatter.title}</h1>
        <p>
          {post.frontmatter.client} · {post.frontmatter.year}
          {post.frontmatter.role && post.frontmatter.role !== '' ? ` · ${post.frontmatter.role}` : ''}
        </p>
        {post.frontmatter.cover && post.frontmatter.cover !== '' ? <GatsbyImage alt='Cover image' className='cover-image' image={getImage(post.frontmatter.cover)} /> : ''}
      </section>
      <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      <footer>
        <Link to='/'>Back home</Link>
      </footer>
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        backgroundColor
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
        client
        role
        title
        year
      }
    }
  }
`;
